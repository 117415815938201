import Gallery from "../../components/swiper/swiper";
import { galleryPics } from "../../utils/home";
import { motion } from "framer-motion";
import { useEffect } from "react";
import "../../scss/global.scss";
import "swiper/css/effect-fade";
import "./home.scss";
import "swiper/css";

const Home: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className='hero'>
                <div className='text-animation'>
                    <motion.img
                        className='hero-img'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 2, delay: 0.75 }}
                        src='../images/hero.png'
                        alt=''
                    />
                </div>
            </div>
            <div className='contain-wrapper'>
                <div className='contain'>
                    <div className='copy'>
                        <h1>
                            Cut from a <br></br>different cloth
                        </h1>
                        <p>
                            A unique thread in the fabric of Kensington's
                            history, One Derry St sits on the site where
                            talented workers, seamstresses, tailors lived and
                            worked, filling the floors of Derry & Tom’s
                            department store with the clothes that spilled out
                            onto the fashionable streets of Kensington.
                        </p>
                        <p
                            style={{
                                paddingTop: "1.5rem",
                                letterSpacing: "1px",
                                fontSize: "24px",
                            }}
                        >
                            DELIVERING Q3 2024
                        </p>
                    </div>
                    <Gallery slides={galleryPics} classNames={" "} />
                    <div className='copy-two'>
                        <p>
                            Now a contemporary warehouse workspace of 30,000 sq
                            ft, the building offers a unique, headquarters
                            opportunity for the next generation of makers, doers
                            and thinkers.
                            <br></br>
                            <br></br>
With an events-ready roof terrace in the style of our famous neighbour, providing a private venue to relax, entertain and bask in lush, green surroundings.
                        </p>
                    </div>
                    <div className='highlights'>
                        <div className='highlight'>
                            <div
                                className='img-holder'
                                style={{
                                    backgroundImage:
                                        "url(../images/highlights/1.jpg)",
                                }}
                            />
                            <div className='highlight-copy'>
                                <h3>
                                    Sustainable <br></br> measures
                                </h3>
                                <p>
                                    Sustainability and the environment have been
                                    considered at every stage of the design.
                                </p>
                            </div>
                        </div>
                        <div className='highlight'>
                            <div
                                className='img-holder'
                                style={{
                                    backgroundImage:
                                        "url(../images/highlights/2.jpg)",
                                }}
                            />
                            <div className='highlight-copy'>
                                <h3>
                                    dressed <br></br> to impress
                                </h3>
                                <p>
                                    The former warehouse has been refurbished to
                                    provide modern workspace whilst retaining
                                    original character.
                                </p>
                            </div>
                        </div>
                        <div className='highlight'>
                            <div
                                className='img-holder'
                                style={{
                                    backgroundImage:
                                        "url(../images/highlights/3.jpg)",
                                }}
                            />
                            <div className='highlight-copy'>
                                <h3>
                                    always <br></br> in fashion
                                </h3>
                                <p>
                                    One Derry St sits in the heart of London’s
                                    fashionable Kensington and is surrounded by
                                    high-end shops and restaurants.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>{" "}
                <div className='border'></div>
            </div>
        </>
    );
};

export default Home;
